<template>
  <LayoutView
    titulo="Vista de Reportes"
    seccionDetalle="Exportación de Reportes"
    :fondo="banner"
  >
    <div class="row">
      <div class="col-12">
        <p class="h5 text-center text-success font-italic mb-5">
          Ingrese el rango de fecha en el que quiere exportar el reporte
        </p>
      </div>

      <div class="col-12 my-5">
        <p class="h4 border-bottom py-3">Estado</p>

        <v-select
          :options="estados"
          :reduce="(e) => e.value"
          label="title"
          :clearable="false"
          placeholder="Seleccione el estado a filtrar"
          v-model="estadoSeleccionado"
          class="mb-3"
        ></v-select>
      </div>

      <!-- CALENDARIOS -->
      <div class="col-12 col-md-6 text-center">
        <p class="h4 border-bottom py-3">Fecha de Inicio</p>

        <b-calendar
          v-model="dateStart"
          :max="dateEnd"
          locale="es-ES"
          label-no-date-selected="Fecha no seleccionada"
          label-next-year="Año Siguiente"
          label-next-month="Mes Siguiente"
          label-current-month="Mes Actual"
          label-calendar="Calendario"
          label-prev-month="Mes Anterior"
          label-prev-year="Año Anterior"
          label-selected="Fecha Seleccionada"
          label-today="Hoy"
        ></b-calendar>
      </div>
      <div class="col-12 col-md-6 text-center mt-5 mt-md-0">
        <p class="h4 border-bottom py-3">Fecha Fin</p>

        <b-calendar
          v-model="dateEnd"
          :min="dateStart"
          locale="es-ES"
          label-no-date-selected="Fecha no seleccionada"
          label-next-year="Año Siguiente"
          label-next-month="Mes Siguiente"
          label-current-month="Mes Actual"
          label-calendar="Calendario"
          label-prev-month="Mes Anterior"
          label-prev-year="Año Anterior"
          label-selected="Fecha Seleccionada"
          label-today="Hoy"
        ></b-calendar>
      </div>

      <!-- EXPORTAR -->
      <div class="col-12 mt-5">
        <b-button
          @click="exportData"
          :disabled="loading || $v.$invalid"
          variant="info"
          size="lg"
          block
        >
          <template v-if="loading">
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span class="sr-only"></span>

            <span class="d-inline-block ml-2">Exportando</span>
          </template>
          <span v-else> Exportar Información</span>
        </b-button>
      </div>
    </div>
  </LayoutView>
</template>

<script>
import LayoutView from "@/layouts/detalles.vue";
import ExportarExcel from "@/apollo/queries/asignaciones/GetExcelReporte.gql";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
import { estados } from "../../../.env";
export default {
  components: { LayoutView },
  mixins: [validationMixin],

  data() {
    return {
      banner: require("@/assets/banner/report.jpg"),
      dateStart: null,
      dateEnd: null,
      loading: false,
      estados: [
        {
          title: "Todos los estados",
          subtitle: "Todos",
          value: "",
          variant: "danger",
          availableTo: [1000],
          user: "Todos",
        },
        ...estados,
      ],
      estadoSeleccionado: "",
    };
  },
  validations: {
    dateEnd: { required },
    dateStart: { required },
  },
  methods: {
    exportData() {
      this.loading = true;
      this.$apollo
        .query({
          query: ExportarExcel,
          fetchPolicy: "no-cache",
          errorPolicy: "all",
          variables: {
            fechaInicial: this.dateStart,
            fechaFinal: this.dateEnd,
            estado: this.estadoSeleccionado,
          },
        })
        .then((res) => {
          this.loading = false;
          if (res.errors) {
            const h = this.$createElement;

            const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
              h("b-spinner", { props: { type: "grow", small: true } }),
              ` Ha ocurrido un error, intentaremos nuevamente. `,
              h("b-spinner", { props: { type: "grow", small: true } }),
            ]);
            // Create the title
            const vNodesTitle = h(
              "div",
              {
                class: [
                  "d-flex",
                  "flex-grow-1",
                  "align-items-baseline",
                  "mr-2",
                ],
              },
              [h("strong", { class: "mr-2" }, "Error Exportando reporte")]
            );

            this.$bvToast.toast([vNodesMsg], {
              title: [vNodesTitle],
              solid: true,
              variant: "danger",
            });
            return;
          }

          const urlExcel = res.data.GetReporteExcel;

          window.open(urlExcel);
        });
    },
  },
};
</script>
